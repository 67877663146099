export const ARTICLE_GLOBAL_OBJECT_ATTRIBUTES = {
    "series":"HashMapStringAttribute",
    "color":"HashMapStringAttribute",
    "operatingSystem":"StringAttribute",
    "networkTechnology":"StringAttribute",
    "hexCode":"StringAttribute",
    "storageSize":"StringAttribute",
    "ram":"StringAttribute",
    "genericSeries":"StringAttribute",
    "dummyDevice":"BooleanAttribute",
    "kidsDevice":"BooleanAttribute"
}

export const LOCALIZE_ARTICLE_LANGUAGE_CSV_OBJECT_ATTRIBUTES = {
    "sku":"HashMapStringAttribute",
    "region":"HashMapStringAttribute",
    "language": "HashMapStringAttribute",
    "type":"HashMapStringAttribute",
    "manufacturerName": "HashMapStringAttribute",
    "series":"HashMapStringAttribute",
    "color":"HashMapStringAttribute"
}

export const LOCALIZE_ARTICLE_COUNTRY_CSV_OBJECT_ATTRIBUTES = {
    "sku": "HashMapStringAttribute",
    "region": "HashMapStringAttribute",
    "simType": "HashMapStringAttribute",
    "modelNumber":"HashMapStringAttribute"
}
export const LOCALIZE_ARTICLE_CARRIER_CSV_OBJECT_ATTRIBUTES = {
    "sku": "HashMapStringAttribute",
    "region": "HashMapStringAttribute",
    "partner": "HashMapStringAttribute",
    "suggestedRetailPrice": "HashMapStringAttribute",
    "tradeInPrice": "HashMapStringAttribute"
}