import { ARTICLE_GLOBAL_OBJECT_ATTRIBUTES } from "../ObjectAttributes";
import { checkAllObjectKeyValue } from "../../utils";
import _ from "lodash";
export const insertGlobalArticleAttributeType = (data) => {
    data.map(record => {
        Object.entries(ARTICLE_GLOBAL_OBJECT_ATTRIBUTES)?.map(([key, value]) => {
            if(record['attributes'] != null && record['attributes'].hasOwnProperty(key)){
                record['attributes'][key]['type'] = value;
                if(record['attributes'][key]['type'] === 'HashMapStringAttribute'){
                    record['attributes'][key]['value'] = {};
                }
            } 
        })
    })

    return data;
};

export const formatLocalizeArticleRecord = (data) => {
    data.map(record => {
        if(typeof record["countryCode"] !== 'undefined') {
            record['countryCode'] = record['countryCode'].slice(0, 2);
        } 
    })

    return data;
};

export const convertToGlobalArticleSystemConfigurationObject = (data, systemConfigurationFormObject, launchDateAndGoLiveObject) => {
    var skuList = [];
    var articleSystemConfigObject = [];
    var countryCodeList = data.newSkuUpdatedByCountryCode;

    console.log("country "+ JSON.stringify(countryCodeList)  )

    data.newSkuUpdatedByCountryCode.map(uploadedSku => {
        skuList.push(uploadedSku.sku);
    })
        countryCodeList.map(systemConfigMapping => {
            var articleSystemConfig = articleSystemConfigObject.filter( data => data["sku"] === systemConfigMapping.sku )[0];
            var systemConfig = {};
            var existingArticleSystemConfigMapping = false;

            if(articleSystemConfig != null){
                existingArticleSystemConfigMapping = true;
                systemConfig = articleSystemConfig;
            } else {

                systemConfig["sku"] = systemConfigMapping["sku"];
            }
            
            systemConfigMapping.countryCodes.map(countryCode => {
                Object.entries(systemConfigurationFormObject).map(([categoryTypeKey, categoryTypeValue]) => {
                    if(typeof systemConfig[categoryTypeKey] === 'undefined'){
                        systemConfig[categoryTypeKey] = {};
                    }
                    if(typeof systemConfig[categoryTypeKey[countryCode]] === 'undefined'){
                        systemConfig[categoryTypeKey][countryCode] = {};
                    }

                    if(checkAllObjectKeyValue(systemConfigurationFormObject[categoryTypeKey]['carrierBased'][countryCode], false)) {
                        if(categoryTypeKey === 'launchDate' || categoryTypeKey === 'goLiveDate') {
                            if(launchDateAndGoLiveObject[categoryTypeKey]) {
                                if(systemConfigurationFormObject[categoryTypeKey]['countryBased'][countryCode] == true) {
                                    systemConfig[categoryTypeKey][countryCode]["type"] = "DateAttribute";
                                    systemConfig[categoryTypeKey][countryCode]["value"] = launchDateAndGoLiveObject[categoryTypeKey];
                                }
                            }
                        } else {
                            systemConfig[categoryTypeKey][countryCode]["type"] = "BooleanAttribute";
                            systemConfig[categoryTypeKey][countryCode]["value"] = systemConfigurationFormObject[categoryTypeKey]['countryBased'][countryCode];
                        }
                        
                    } else {
                        systemConfig[categoryTypeKey][countryCode]["value"] = {};
                        if(categoryTypeKey === 'launchDate' || categoryTypeKey === 'goLiveDate') {
                            if(launchDateAndGoLiveObject[categoryTypeKey]) {
                                systemConfig[categoryTypeKey][countryCode]["type"] = "HashMapDateAttribute";
                                Object.entries(systemConfigurationFormObject[categoryTypeKey]['carrierBased'][countryCode]).map(([carrierCode, carrierValue]) => {
                                    if(carrierValue == true) {
                                        systemConfig[categoryTypeKey][countryCode]["value"][carrierCode] = launchDateAndGoLiveObject[categoryTypeKey];
                                    }
                                })
                            }
                        } else {
                            systemConfig[categoryTypeKey][countryCode]["type"] = "HashMapBooleanAttribute";
                            Object.entries(systemConfigurationFormObject[categoryTypeKey]['carrierBased'][countryCode]).map(([carrierCode, carrierValue]) => {
                                systemConfig[categoryTypeKey][countryCode]["value"][carrierCode] = carrierValue;
                            })
                        }
                    }

                    if((categoryTypeKey === 'launchDate' || categoryTypeKey === 'goLiveDate') && _.isEmpty( systemConfig[categoryTypeKey][countryCode])) {
                        delete systemConfig[categoryTypeKey][countryCode];
                    }

                    if(categoryTypeKey === 'goLiveDate' && !launchDateAndGoLiveObject['goLiveDate']) {
                        delete systemConfig['goLiveDate'];
                    }

                    if(categoryTypeKey === 'launchDate' && !launchDateAndGoLiveObject['launchDate']) {
                        delete systemConfig['launchDate'];
                    }
                })
            })

            if(existingArticleSystemConfigMapping === true) {
                var foundIndex = articleSystemConfigObject.findIndex(x => x.sku === systemConfigMapping.sku);
                articleSystemConfigObject[foundIndex] = systemConfig;
            } else {
                articleSystemConfigObject.push(systemConfig);
            }
        })
        console.log("Generate "+ JSON.stringify(articleSystemConfigObject))

    return articleSystemConfigObject;
};

export const convertToLocalisedArticleObject = (data, systemConfigurationArray, launchDateAndGoLiveObject) => {
    var localisedData = {};
    var article = [];
    var articleTradeInPriceObject =[];
    var articleSystemConfigObject = [];
    var articleSuggestedRetailPrice = [];

    data.newUpdatedArticles.map(articleData => {
        articleData.article["description"] = null;
        article.push(articleData.article)
        articleTradeInPriceObject.push(articleData.tradeInPrice);
        articleSuggestedRetailPrice.push(articleData.suggestedRetailPrice);
        articleSystemConfigObject.push(articleData.systemConfiguration);
    })

    localisedData["article"] = article;
    localisedData["articleTradeInPriceObject"] = articleTradeInPriceObject;
    localisedData["articleSuggestedRetailPrice"] = articleSuggestedRetailPrice;
    localisedData["articleSystemConfigObject"] = articleSystemConfigObject;

    return localisedData;
}

export const formatGlobalArticleRecord = (data) => {
    data.map((article) => {
        if(article.foldable.toLowerCase() === "yes") {
            article.foldable = true
        } else {
            article.foldable = false
        }

        for (let key in article.attributes) {
            if (typeof article.attributes[key].value === 'string') {
                let lowerCaseValue = article.attributes[key].value.toLowerCase();
                if (lowerCaseValue === "true") {
                    article.attributes[key].value = true;
                } else if (lowerCaseValue === "false") {
                    article.attributes[key].value = false;
                }
            }
        }

        if(article.attributes.operatingSystem.value != null){
            var originalOperatingSystemValue = article.attributes.operatingSystem.value;
            article.attributes.operatingSystem.value = originalOperatingSystemValue.toLowerCase() == "ios" ? "iOS" : originalOperatingSystemValue.charAt(0).toUpperCase() + originalOperatingSystemValue.slice(1)
        }
    })

    return data;
}

