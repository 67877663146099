import {
    ARTICLE_LIKEFOR_REFRESH_REQUEST_FILTER,
    ARTICLE_LIKEFOR_REFRESH_REQUEST_GET_LIST,
    ARTICLE_LIKEFOR_REFRESH_REQUEST_CLEAR_FILTER, 
    ARTICLE_LIKEFOR_REFRESH_REQUEST_SORT
} from "../actions/types";

const INITIAL_STATE = {
    requestList: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    requestListFilter: {},
}

const articleLikeForRefreshReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ARTICLE_LIKEFOR_REFRESH_REQUEST_GET_LIST:
            return {
                ...state,
                requestList: action.payload.data
            }
        case ARTICLE_LIKEFOR_REFRESH_REQUEST_FILTER:
            return {
                ...state,
                requestListFilter: {
                    ...state.requestListFilter,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case ARTICLE_LIKEFOR_REFRESH_REQUEST_CLEAR_FILTER:
            return {
                ...state,
                requestListFilter: {}
            }
        case ARTICLE_LIKEFOR_REFRESH_REQUEST_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default articleLikeForRefreshReducer;