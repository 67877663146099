import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    ARTICLE_LIKEFOR_REFRESH_REQUEST_FILTER,
    ARTICLE_LIKEFOR_REFRESH_REQUEST_GET_LIST,
    ARTICLE_LIKEFOR_REFRESH_REQUEST_CLEAR_FILTER,
    ARTICLE_LIKEFOR_REFRESH_REQUEST_SORT,
    SHOW_SUCCESS, HIDE_CONFIRMATION_DIALOG
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getLikeForRefreshRequestList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/current/article/articleRecommendationSyncEntities/?${path}`)
        .then(response => {
            console.log("response", response);
            dispatch({
                type: ARTICLE_LIKEFOR_REFRESH_REQUEST_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForLikeForRefreshRequestList = (filterKey, filterValue) => {
    return({
        type: ARTICLE_LIKEFOR_REFRESH_REQUEST_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearLikeForRefreshRequestFilters = () => {
    return ({
        type: ARTICLE_LIKEFOR_REFRESH_REQUEST_CLEAR_FILTER
    })
}

export const sortLikeForRefreshRequestUpdate = (orderBy, sort) => {
    return ({
        type: ARTICLE_LIKEFOR_REFRESH_REQUEST_SORT,
        payload: {orderBy, sort}
    })
}