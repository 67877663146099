import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {TableContainer, Table, TableRow, TableCell, TableBody } from "@material-ui/core";

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white",
    },
    headers: {
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 2
    }
})

const ArticleGlobalUploadPreviewTable = ({entity}) => {
    const { t } = useTranslation();
    const customStyleClass = customStyles();
      
    return (
        <TableContainer style={{ maxHeight: 300}}>
            <Table aria-label="a dense table">
                <TableBody>
                    <TableRow  className={customStyleClass.headers}>
                        <TableCell className={customStyleClass.sticky}><b>{t('keys.article.sku.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.manufacturerName.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.series.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.color.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.hexCode.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.ram.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.type.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.networkTechnology.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.tagForMapping.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.description.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.operatingSystem.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.storageSize.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.foldable.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.genericSeries.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.dummyDevice.value.keyword')}</b></TableCell>
                        <TableCell><b>{t('keys.article.attributes.kidsDevice.value.keyword')}</b></TableCell>
                    </TableRow>
                    {                
                        entity != null ? (
                            
                            entity.map((article, index) => (
                                <TableRow key={index}>
                                    <TableCell className={customStyleClass.sticky}> {`SKU ${article.sku}`}</TableCell>
                                    <TableCell> {article.manufacturer.code}</TableCell>
                                    <TableCell> {article.attributes?.series?.global}</TableCell>
                                    <TableCell> {article.attributes?.color?.global}</TableCell>
                                    <TableCell> {article.attributes?.hexCode?.value}</TableCell>
                                    <TableCell> {article.attributes?.ram?.value}</TableCell>
                                    <TableCell> {article.type?.code}</TableCell>
                                    <TableCell> {article.attributes?.networkTechnology?.value}</TableCell>
                                    <TableCell> {article.tagForMapping}</TableCell>
                                    <TableCell> {article.description}</TableCell>
                                    <TableCell> {article.attributes?.operatingSystem?.value}</TableCell>
                                    <TableCell> {article.attributes?.storageSize?.value}</TableCell>
                                    <TableCell> {article.foldable}</TableCell>
                                    <TableCell> {article.attributes?.genericSeries?.value}</TableCell>
                                    <TableCell> {article.attributes?.dummyDevice?.value}</TableCell>
                                    <TableCell> {article.attributes?.kidsDevice?.value}</TableCell>
                                </TableRow>
                            ))
                        ) : (<TableRow></TableRow>)
                    }
                </TableBody>

                
            </Table>
        </TableContainer>
    )
}

export default ArticleGlobalUploadPreviewTable;