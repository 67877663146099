import Axios from "axios";
import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    LOG_IN,
    LOG_OUT,
    THIRD_PARTY_LOG_IN,
    ERROR,
    SET_RESET_PASSWORD_TOKEN,
    REQUEST_START,
    REQUEST_SUCCESS, REQUEST_FAILURE, OTP_GET,
    LOGIN_PAGE_ENABLED
} from "./types";

const handleError = (error, dispatch) => {
    dispatch({
        type: ERROR,
        payload: error.response
    })
}

export const logIn = (data) => async dispatch => {
    Axios({
        method: 'POST',
        data: data,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/current/account/authenticate`
    }).then(response => {
        dispatch({
            type: LOG_IN,
            payload: response
        })
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const thirdPartyLogIn = (payload) => {
    return({
        type: THIRD_PARTY_LOG_IN,
        payload: payload
    })
}

export const logOut = () => {
    return({
        type: LOG_OUT
    })
}

export const sendForgotPasswordEmail = (email) => async dispatch => {
    dispatch({
        type: REQUEST_START
    })
    Axios({
        method: 'POST',
        data: email,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/current/account/forgotPassword`
    }).then(() => {
        dispatch({
            type: REQUEST_SUCCESS
        })
    }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}

export const setResetPasswordToken = (token) => {
    return({
        type: SET_RESET_PASSWORD_TOKEN,
        payload: token
    })
}

export const getOtp = (data) => async dispatch => {
    Axios({
        method: 'POST',
        data: data,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/current/account/forgotPasswordOtp`
    }).then(response => {
        dispatch({
            type: OTP_GET,
            payload: response
        })
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const submitForgotPasswordConfirmation = (data) => async  dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    dispatch({
        type: REQUEST_START
    })
    Axios({
        method: 'POST',
        data: sanitizedData,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/current/account/forgotPasswordConfirmation`
    }).then(() => {
        dispatch({
            type: REQUEST_SUCCESS
        })
    }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}

export const changePassword = (data) => async dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    dispatch({
        type: REQUEST_START
    })
    axios.post(`${BASE_URL}/current/account/changePassword`, sanitizedData)
        .then(() => {
            dispatch({
                type: LOG_OUT
            })
            dispatch({
                type: REQUEST_SUCCESS
            })
        }).catch(error => {
            dispatch({
                type: REQUEST_FAILURE
            })
            handleError(error, dispatch);
        })
}

export const setNewPasswordRequest = (data) => async dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    console.log(data)
    dispatch({
        type: REQUEST_START
    })
    axios.post(`${BASE_URL}/current/account/setNewPassword`, sanitizedData)
        .then(() => {
            dispatch({
                type: LOG_OUT
            })
            dispatch({
                type: REQUEST_SUCCESS
            })
        }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}

export const getLoginPageEnabled = () => async dispatch => {
    axios.get(`${BASE_URL}/current/configAuthExcluded/getLoginPageConfig`)
    .then(response => {
            console.log("response", response);
    dispatch({
        type: LOGIN_PAGE_ENABLED,
        payload: response
    })
    }).catch(error => {
        handleError(error, dispatch);
    })
}