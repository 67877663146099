import React from 'react';
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import { CloudUploadOutlined, ExpandMore } from "@material-ui/icons"; 

const ArticleUploadAccordion = ({ templateFile, forms, processCSVFile, open, multiTemplateDownload, showDownloadTemplateDialog}) => {
    const drop = React.useRef(null);
    const hiddenFileInput = React.useRef(null);
    const [dragging, setDragging] = React.useState(false);
    const [file, setFile] = React.useState(undefined);
    const [formInput, setFormInput] = React.useState(undefined);
    const { t } = useTranslation();
    console.log(formInput)

    React.useEffect(() => {
        const dropRef = drop.current;
        dropRef.addEventListener('dragover', handleDragOver);
        dropRef.addEventListener('drop', handleDrop);
        dropRef.addEventListener('dragenter', handleDragEnter);
        dropRef.addEventListener('dragleave', handleDragLeave);
        return () => {
            dropRef.removeEventListener('dragover', handleDragOver);
            dropRef.removeEventListener('drop', handleDrop);
            dropRef.removeEventListener('dragenter', handleDragEnter);
            dropRef.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    React.useEffect(() => {
        if(!formInput) {
            forms?.map((form) => {
                if(form.defaultValue) {
                    setFormInput((prevInput) => ({
                        ...prevInput,
                        [form.id]: form.defaultValue,
                    }));
                }
            })
        }
    }, [forms]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
     };

    const onFileUpload = (files) => {
        setFile(files);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);

        const {files} = e.dataTransfer;

        if (files && files.length && files[0].type === 'text/csv') {
            onFileUpload(files[0]);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        setDragging(true);
    };
      
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        setDragging(false);
    };


    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const onFileInputClick =event => {
        event.target.value = '';
    }

    const handleChange = event => {
        onFileUpload(event.target.files[0])
    };

    const handleNextAccordion = () => {
        processCSVFile(file, formInput?.operationType);
        setFile(null);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormInput((prevInput) => ({
            ...prevInput,
            [name]: value,
        }));
    }

    const styles = {
        accordion: {
            maxWidth: '97%'
        },
        accordionDetails: {
            width: '100%',
            height: '200px',
            padding: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexFlow: 'column nowrap',
            fontSize: '24px',
            color:' #555555',
            border: '2px #c3c3c3 dashed',
            borderRadius: '12px',
            cursor: 'grab',
        },
        accordionFormDiv: {
            marginLeft: '15px'
        },
        circledNumber: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            m: 1,
            border: 2,
            width: '40px',
            height: '40px',
            borderRadius: '50%'
        },
        downloadDiv: {
            display: "flex",
            justifyContent: "flex-end"
        },
        nextButton: {
            background: "#E3D900",
            padding: "10px 50px",
            margin: '30px',
            float: 'right'
        }
    }

    const renderForm =(form, index) => {
        switch (form.type) {
            case 'checkBox':
                return (
                    <div style={styles.accordionFormDiv} key={form.id + index}>
                        <AccordionDetails>
                            
                                <FormControl>
                                    <FormLabel>{t(form.label)}</FormLabel>
                                        <FormLabel>
                                            <RadioGroup id={form.id} name={form.id} row defaultValue={form.defaultValue} onChange={handleInputChange} > 
                                                {
                                                    form.options.map((option, index) => {
                                                        return (
                                                            <FormControlLabel key={index} value={option.value} control={<Radio/>} label={t(option.label)} />
                                                        )
                                                    })
                                                }
                                            </RadioGroup>
                                        </FormLabel>
                                </FormControl>

                        </AccordionDetails>
                    </div>
                );
            default:
                return;
        }

    }
      
    return (

        <Accordion expanded={open} style={styles.accordion}>
            <AccordionSummary>
                {
                    open ? 
                     <Box sx={{ ...styles.circledNumber, bgcolor: '#180f4f' }}>
                        <Typography style={{color: "#ffffff"}}>1</Typography>
                    </Box> :

                    <Box sx={{ ...styles.circledNumber, bgcolor: '#E6F9FA' }}>
                        ✔️
                    </Box>
                }
               
                <div>
                    <Typography>
                        {t('messages.uploadFile')}     
                        <br />
                        {t('messages.importCSVTemplate')}
                    </Typography>
                </div>
                
         
                <Box style={{ marginLeft: 'auto', display: !open ? 'none' : '' }}>
                    <div style={styles.downloadDiv} >
                        {
                            !multiTemplateDownload ? 
                            <a href={`${process.env.PUBLIC_URL}/assets/resources/${templateFile}`} target='_blank' rel="noreferrer" download title={t('messages.downloadTemplateFile')} >{t('messages.downloadTemplateFile')} <ExpandMore /></a> :
                            <a onClick={() =>showDownloadTemplateDialog()} target='_blank' rel="noreferrer" download title={t('messages.downloadTemplateFile')} >{t('messages.downloadTemplateFile')} <ExpandMore /></a>
                        }
                    </div>

                </Box>
             
            </AccordionSummary>
            {
                forms && forms.length > 0 && (
                    forms.map((form, index) => {
                        return renderForm(form, index)
                    })
                )

            }
           
            <AccordionDetails>
                <div
                    ref={drop}
                    onClick={handleClick}
                    style={{
                        ...styles.accordionDetails,
                        backgroundColor: dragging ? 'grey' : 'white',
                    }}>
                    <>
                        {!file ? <CloudUploadOutlined style={{pointerEvents: "none"}} fontSize="large"></CloudUploadOutlined> :
                            <Typography style={{pointerEvents: "none"}} >{t('messages.uploadedFileName')} : <span style={{color: "blue"}}>{file.name}</span></Typography>
                        }
                        <Typography style={{pointerEvents: "none"}} ref={drop}>{t('messages.dragAndDropOrBrowseFile')}</Typography>
                        <Typography style={{pointerEvents: "none"}} ref={drop}>{t('messages.csvOnly')}</Typography>
                        <input
                            id="fileUpload"
                            type="file"
                            name="file"
                            onChange={handleChange}
                            onClick={onFileInputClick}
                            ref={hiddenFileInput}
                            seleniumselector="fileUpload"
                            accept=".csv"
                            style={{ display: "none" }}
                        />
                    </>
                </div>
                
            </AccordionDetails>

            <Button size="small" variant="contained" color="secondary" 
                onClick={handleNextAccordion}
                disabled={!file}
                style={styles.nextButton}>
                {t('messages.next')}
            </Button>
        </Accordion>
    )
}

export default ArticleUploadAccordion;