import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../../components/PageTemplate";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";

class MyAccount extends Component {
    componentDidMount() {
       
    }

    render() {
        const { t } = this.props;
        const user = this.props.authentication.loggedInUser;
        return (
            <PageTemplate title="messages.myAccount">
                <Grid container item md={12} spacing={5}>
                    <Grid item md={3}>
                        <Typography variant="h6" style={{marginLeft: "10px"}} className="grouping-header">{t(`messages.generalInformation`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.name')}</Typography>
                                <p className="display-value">{user.name}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.email')}</Typography>
                                <p className="display-value">{user.email}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.countryCode')}</Typography>
                                <p className="display-value">{user.countryCode}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.userId')}</Typography>
                                <p className="display-value">{user.id}</p>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="h6" className="grouping-header">{t(`keys.authorities`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                {
                                    user.authorities.map(authority => {
                                        return (<p key={authority} className="display-value">{t(`values.authorities.${authority}`)}</p>)
                                    })
                                }
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
})

export default connect(mapStateToProps, {
})(withTranslation()(MyAccount));