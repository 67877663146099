import React, {Component} from "react";
import { connect } from 'react-redux';
import { getLikeForRefreshRequestList, sortLikeForRefreshRequestUpdate,
    updateFiltersForLikeForRefreshRequestList, clearLikeForRefreshRequestFilters
 } from "../../actions/articleLikeForRefreshAction";
import DataTable from "../../components/DataTable";
import PageTemplate from "../../components/PageTemplate";
import {
    parseObjectToString,
} from "../../utils/utils";
import {showError} from "../../actions/commonActions";

class ArticleLikeForRefreshRequest extends Component {
    componentDidMount() {
        this.props.getLikeForRefreshRequestList('orderBy=createdAt&sort=asc');
        this.props.clearLikeForRefreshRequestFilters();
        this.props.sortLikeForRefreshRequestUpdate('createdAt', 'asc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {}

    handleFilterReset() {}

    handleSorting(orderBy, sort) {
        this.props.sortLikeForRefreshRequestUpdate(orderBy, sort)
        this.props.getLikeForRefreshRequestList(parseObjectToString(this.props.articleLikeForRefresh.requestListFilter, {orderBy, sort}))
    }

    renderRequestList(requestList, showContentLoader) {
        const fields = [
            {
                name: 'recommendation.tagForMapping',
            },
            {
                name: 'recommendation.countryCode'
            },
            {
                name: 'recommendation.carrierCode',
            },
            {
                name: 'syncStatus',
                type: 'translatedString'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }
        ];

        const filterKeys = [];

        return(
            <DataTable fields={fields}
                       items={requestList}
                       redirectId={null}
                       entity="requestList"
                       filterKeys={filterKeys}
                       filterValues={this.props.articleLikeForRefresh.requestListFilter}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getLikeForRefreshRequestList(parseObjectToString(this.props.articleLikeForRefresh.requestListFilter))}
                       sorting={this.props.articleLikeForRefresh.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
                       disableCell={true}
                       enableFilter={false}
            />
        )
    }

    render() {

        return(
            <PageTemplate title="messages.l4sRefreshRequest">
                {this.renderRequestList(this.props.articleLikeForRefresh.requestList, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    articleLikeForRefresh: state.articleLikeForRefresh,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getLikeForRefreshRequestList: getLikeForRefreshRequestList,
    updateFiltersForLikeForRefreshRequestList: updateFiltersForLikeForRefreshRequestList,
    clearLikeForRefreshRequestFilters: clearLikeForRefreshRequestFilters,
    sortLikeForRefreshRequestUpdate: sortLikeForRefreshRequestUpdate,
    showError
})(ArticleLikeForRefreshRequest);