import {
    LOG_IN,
    LOG_OUT,
    ERROR,
    SET_RESET_PASSWORD_TOKEN,
    OTP_GET,
    THIRD_PARTY_LOG_IN,
    LOGIN_PAGE_ENABLED
} from "../actions/types";

const isAuthenticated = () => {
    const token = localStorage.getItem("global-article-master-token")
    return !!token
}

const getUser = () => {
    const user = localStorage.getItem("global-article-master-user")
    return user ? JSON.parse(user) : {}
}

const getToken = () => {
    const token = localStorage.getItem("global-article-master-token")
    return token ? token : undefined
}

const getAuthorityList = () => {
    const authorityList = localStorage.getItem("global-article-master-authority-list")
    return authorityList ? JSON.parse(authorityList) : {}
}

const getPasswordExpired = () => {
    const passwordExpired = localStorage.getItem("global-article-master-password-expired")
    return passwordExpired ? passwordExpired : undefined
}

const setAuthentication = (token, user, authorityList) => {
    localStorage.setItem("global-article-master-token", token);
    localStorage.setItem("global-article-master-user", JSON.stringify(user));
    localStorage.setItem("global-article-master-authority-list", JSON.stringify(authorityList));
    localStorage.setItem("i18nextLng", user.languageCode)
    localStorage.setItem("global-article-master-password-expired", (new Date(user.passwordValidUntil) < new Date()).toString())
}

const removeAuthentication = () => {
    localStorage.removeItem("global-article-master-token");
    localStorage.removeItem("global-article-master-user");
    localStorage.removeItem("global-article-master-authority-list")
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("global-article-master-password-expired");
    window.history.pushState({}, null, "/login");
}

const INITIAL_STATE = {
    authenticated: isAuthenticated(),
    loggedInUser: getUser(),
    authorityList: getAuthorityList(),
    token: getToken(),
    errorCode: undefined,
    errorMessage: undefined,
    forgetPasswordConfirmationRequest: {
        token: '',
        otpId: ''
    },
    passwordExpired: getPasswordExpired(),
    loginPageEnabled: {}
}

const authenticationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_IN:
            if (action.payload.data.token && action.payload.data.user) {
                setAuthentication(action.payload.data.token, action.payload.data.user, action.payload.data.authorityList);
                return {
                    ...state,
                    authenticated: true,
                    loggedInUser: action.payload.data.user,
                    authorityList: action.payload.data.authorityList,
                    token: action.payload.data.token,
                    passwordExpired: (new Date(action.payload.data.user.passwordValidUntil) < new Date()).toString()
                }
            } else {
                return {
                    ...state,
                    authenticated: false,
                    user: undefined,
                    authorityList: undefined,
                    token: undefined,
                }
            }
        case THIRD_PARTY_LOG_IN:
            setAuthentication(action.payload.token, action.payload.user, action.payload.authorities);
            return {
                ...state,
                authenticated: true,
                loggedInUser: action.payload.user,
                authorityList: action.payload.authorities,
                token: action.payload.token,
                passwordExpired: "false"
            }
        case SET_RESET_PASSWORD_TOKEN:
            return {
                ...state,
                forgetPasswordConfirmationRequest: {
                    ...state.forgetPasswordConfirmationRequest,
                    token: action.payload.token
                }
            }
        case OTP_GET:
            return {
                ...state,
                forgetPasswordConfirmationRequest: {
                    ...state.forgetPasswordConfirmationRequest,
                    otpId: action.payload.data.otpId
                }
            }
        case LOG_OUT:
            removeAuthentication();
            return {
                ...state,
                authenticated: false,
                user: undefined,
                authorityList: undefined,
                token: undefined,
                passwordExpired: undefined,
                errorMessage: action.payload ? action.payload.data.translationKey : undefined
            }
        case ERROR:
            return {
                ...state,
                errorCode: action.payload.data.errorCode,
                errorMessage: action.payload.data.translationKey
            }
        case LOGIN_PAGE_ENABLED:
            return {
                ...state,
                loginPageEnabled: action.payload.data
            }
        default:
            return state;
    }
}

export default authenticationReducer;